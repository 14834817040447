import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure your CSS supports background images

// Import images
import overviewImage from './assets/images/company.jpg';
import whyImage from './assets/images/why.jpg';
import servicesImage from './assets/images/services.png';
import contactImage from './assets/images/contact.jpg';

// Interface for Section Component Props
interface SectionProps {
  title: string;
  text: string;
  id: string;
  backgroundImage: string; // This will now be the imported image reference
  children?: React.ReactNode;
}

// Section Component with background image
const Section: React.FC<SectionProps> = ({ title, text, id, backgroundImage, children }) => (
  <div className="section" id={id} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    <h1>{title}</h1>
    <p>{text}</p>
    {children}
  </div>
);

// App Component
const App: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>('');

  useEffect(() => {
    const handleScroll = (): void => {
      const sections = document.querySelectorAll('.section');
      let currentId = '';
      sections.forEach((section) => {
        const sectionElement = section as HTMLElement;
        const sectionTop = sectionElement.offsetTop;
        if (window.scrollY >= sectionTop - 50) {
          currentId = sectionElement.getAttribute('id') ?? '';
        }
      });
      setCurrentSection(currentId);
    };

    // const mainElement = document.getElementById("main");
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id: string): void => {
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id="main">
      <nav className="navigation">
        {['overview', 'why', 'service', 'apply'].map((id) => (
          <button key={id} className={currentSection === id ? 'active' : ''} onClick={() => scrollToSection(id)}>
            {id.charAt(0).toUpperCase() + id.slice(1)}
          </button>
        ))}
      </nav>
      <Section 
        id="overview" 
        title="Have you recently been laid off from a tech job?" 
        text="Or maybe you're just looking for a new role..." 
        backgroundImage={overviewImage}
      />
      <Section 
        id="why" 
        title="Are you able to stand out in the crowd?" 
        text="Recruiters are asking the same questions over and over..." 
        backgroundImage={whyImage}
      />
      <Section 
        id="service" 
        title="We will train an AI chatbot for you. To be YOU." 
        text="You get a sharable chatbot that can explain you, your experience, and why companies should hire you." 
        backgroundImage={servicesImage}
      />
      <Section 
        id="apply" 
        title="We are in Alpha now!" 
        text="Apply to be on the wait list and we will let you in when we are ready." 
        backgroundImage={contactImage}
      >
        <a href='https://forms.gle/Cn96eNCZfw3rJQtg8'>Apply to be on the wait list!</a>
        <a href='https://chat.vincentchu.com'>Demo</a>
      </Section>
    </div>
  );
};

export default App;
